import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IFAQDetailForm {
  isEdit?: boolean;
  selectedFAQ?: any;
  setFAQDetail?: any;
}

const FAQDetailForm: React.FC<IFAQDetailForm> = ({
  selectedFAQ,
  isEdit,
  setFAQDetail,
}) => {
  console.log(selectedFAQ);

  return (
    <Col>
      <Row>
        <Col sm={12} lg={2} md={12}>
          <div style={{ padding: "5px" }}>
            <p className="employee-title">
              <FormattedMessage id="FAQ ID" />
            </p>
            <input
              type="text"
              value={selectedFAQ?.id}
              disabled
              className="employee-form-input"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Title" />
            </p>
            <input
              type="text"
              value={selectedFAQ?.title}
              disabled={isEdit}
              onChange={(e) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Title (Ar)" />
            </p>
            <input
              type="text"
              style={{ textAlign: "right" }}
              value={selectedFAQ?.title_ar}
              disabled={isEdit}
              onChange={(e) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  title_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Description" />
            </p>
            <ReactQuill
              value={selectedFAQ?.description}
              readOnly={isEdit}
              onChange={(value) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  description: value,
                }));
              }}
              className="quill-editor"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["clean"], // remove formatting button
                ],
              }}
            />
            {/* No RTL styles applied here */}
          </div>
        </Col>

        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Description (Ar)" />
            </p>
            <ReactQuill
              value={selectedFAQ?.description_ar}
              readOnly={isEdit}
              onChange={(value) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  description_ar: value,
                }));
              }}
              className="quill-editor rtl-quill-editor" // Custom RTL class for Arabic
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["clean"], // remove formatting button
                ],
              }}
            />
            {/* Apply RTL styling for only this editor */}
            <style>{`
      .rtl-quill-editor .ql-editor {
        direction: rtl;
        text-align: right;
      }
    `}</style>
          </div>
        </Col>
      </Row>
    </Col>
  );
};
export default FAQDetailForm;
