import  Layout  from "../Layout/Layout";

const Head = () => {
  return (
    <>
      <Layout isAdmin={false} isHead={true} />
    </>
  );
};

export default Head;
