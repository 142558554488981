import { SystemConfiguration } from "../../components/SystemConfiguration";
import  Layout  from "../Layout/Layout";

const Admin = () => {
  return (
    <>
      <Layout isAdmin={true} />
      {/* <SystemConfiguration /> */}
      {/* <EmployeeDashboard /> */}
      {/* </Layout> */}
    </>
  );
};

export default Admin;
