import { MoreOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Avatar, Input, MenuProps, Switch, Tag, Checkbox } from "antd";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { CustomTable } from "./Table";
import { TableHeader } from "./TableHeader";
import DashboardIcon from "../assets/svg/DashboardIcon";
import OpenCloseSIderIcon from "../assets/svg/OpenCloseSider";
import SettingSearchIcon from "../assets/svg/SettingSearchIcon";
import CopyCloseIcon from "../assets/svg/CopyCloseIcon";

interface IEmployeeDataType {
  key: string;
  fullname: string;
  job_title: string;
  mobile_no: string;
  id: string;
}

const CustomerTab = () => {
  const [CustomersData, setCustomersData] = useState([]);
  const [CustomersFilterData, setCustomersFilterData] = useState([]);

  const CustomerColumns: ColumnsType<IEmployeeDataType> = [
    {
      title: "Number",
      dataIndex: "id",
      width: "20px",
      render: (text) => (
        <span
          style={{
            fontSize: "16px",
            color: "#3E6E4B",
            fontFamily: "Alexandria",
            fontWeight: "700",
            wordWrap: "break-word",
          }}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.id.length - b.id.length,
      sortDirections: ["descend"],
    },
    {
      title: "Customer name",
      dataIndex: "fullname",
      width: 10,
      render: (text, record) => {
        const parts = text?.split(",");
        return (
          <div
            className="avatar-name-container"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Avatar shape="square" size="large" icon={<img src={parts[1]} />} />
            <span>{parts[0]}</span>
          </div>
        );
      },

      sorter: (a, b) => a.fullname.length - b.fullname.length,
      sortDirections: ["descend"],
    },
    {
      title: "Job Title",
      dataIndex: "jobtype",
      width: 6,

      render: (text, record) => <span>{text}</span>,
      //   sorter: (a, b) => a.position.length - b.position.length,
      sortDirections: ["descend"],
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 6,

      render: (text, record) => <span>{text}</span>,
      //   sorter: (a, b) => a.section.length - b.section.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <input
          type="checkbox"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
      dataIndex: "",
      width: 6,

      render: (text, record) => (
        <span>
          <input
            type="checkbox"
            style={{
              width: "18px",
              height: "18px",
            }}
          />
        </span>
      ),
      //   sorter: (a, b) => a.section.length - b.section.length,
      sortDirections: ["descend"],
    },
  ];

  useEffect(() => {
    axios
      .get(`https://appadmin.buyinpk.com/portalapis/Notifications/getCustomers`)
      .then((response: any) => {
        let tempArr: any = [];
        /* For Customers */
        response?.data?.data?.map((d: any, index: number) => {
          tempArr.push({
            id: d?.id,
            fullname: d?.fullname,
            mobile: d?.mobile_no,
            jobtype: d?.job_title,
            data: "Customers",
          });
        });
        setCustomersData(tempArr);
      });
  }, []);

  const onRow = (record: any) => {
    console.log(" onRow:", record);
  };

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    filterData(value);
  };

  const filterData = (input: any) => {
    const filteredData = CustomersData?.filter((item: any) => {
      const name = item.fullname ? item.fullname.toLowerCase() : "";

      const lowerInput = input.toLowerCase();

      return name.includes(lowerInput);
    });

    setCustomersFilterData(filteredData);
  };

  return (
    <>
      <div
        style={{ backgroundColor: "#daf4e1", padding: "20px 20px 0px 20px" }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "15px",
            padding: "20px",
            color: "#6e4c3e",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                width: "25px",
                height: "25px",
                marginRight: "10px",
              }}
              name="All"
            />
            <label htmlFor="All">
              <h5>All Users</h5>
            </label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                width: "25px",
                height: "25px",
                marginRight: "10px",
              }}
              name="Specific"
            />
            <label htmlFor="Specific">
              <h5>Specific Users</h5>
            </label>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            backgroundColor: "white",
            padding: "9px 16px 9px 16px",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              gap: "162px",
              display: "flex",
              // flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <span className="table-header-p">{"Customers"}</span>
              <p style={{ color: "#535353", marginBottom: "2px" }}>
                {CustomersData?.length} Results
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "324px",
              }}
            >
              <Input
                size="small"
                style={{
                  background: "#eeeeee",
                  border: "none",
                  padding: "10px",
                }}
                placeholder="Search Customer By Name"
                prefix={<SettingSearchIcon width={24} height={24} />}
                width={250}
                height={48}
                onChange={handleSearchChange}
              />
              {/* <LinesIcon width={26} height={24} /> */}
            </div>
          </div>
        </div>

        <div>
          <CustomTable
            isSkelton={false}
            columns={CustomerColumns}
            data={
              CustomersFilterData?.length > 0
                ? CustomersFilterData
                : CustomersData
            }
            onRow={onRow}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerTab;
