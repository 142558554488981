import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

interface IServiceForm {
  isEdit?: boolean;
  serviceDetail?: any;
  setServiceDetail?: any;
}
const ServiceForm: React.FC<IServiceForm> = ({
  isEdit,
  serviceDetail,
  setServiceDetail,
}) => {
  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    const arr: any = [];
    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Departments/list`)
      .then((res) => {
        res.data.data.map((d: any) => {
          arr.push({ name: d?.department?.name, id: d?.department?.id });
        });
        setDepartments(arr);
      });
  }, []);
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">Service ID</p>
            <input
              disabled
              type="text"
              value={serviceDetail?.id}
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">Service Code</p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.service_code}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  service_code: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">Service Name</p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.service_name}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  service_name: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">Service Name (Ar)</p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.service_name_ar}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  service_name_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
              style={{
                textAlign: "right",
              }}
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">Department</p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              value={serviceDetail?.department_id}
              size={1}
              onChange={(e) =>
                setServiceDetail((prev: any) => ({
                  ...prev,
                  department_id: e.target.value,
                }))
              }
            >
              <option value={""} selected disabled>
                Select Department
              </option>
              {departments.map((depart: any, i: any) => {
                return (
                  <option key={i} value={depart.id}>
                    {depart?.name}
                  </option>
                );
              })}
            </select>
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <div className="inputs-label-col" style={{ marginTop: "40px" }}>
            <Switch
              disabled={isEdit}
              checked={serviceDetail?.without_selecting_plants_step}
              onChange={(event) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  without_selecting_plants_step:
                    !prev?.without_selecting_plants_step,
                }));
              }}
            />{" "}
            Without selecting plants step
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ServiceForm;
