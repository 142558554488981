import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./style.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IServiceDetails {
  serviceDetail: any;
  setServiceDetail: any;
  isEdit: boolean;
}
const ServiceDetails: React.FC<IServiceDetails> = ({
  serviceDetail,
  setServiceDetail,
  isEdit,
}) => {
  return (
    <div>
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">
            <FormattedMessage id="details" />
          </h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">Service description</p>
            <ReactQuill
              value={serviceDetail?.description}
              readOnly={isEdit}
              onChange={(value) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  description: value,
                }));
              }}
              className="quill-editor"
              modules={{
                toolbar: [
                  // container:"border-2",
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  // [{ 'direction': 'rtl' }],
                  ["clean"], // remove formatting button
                ],
              }}
            />
            {/* <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.description}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
              className="employee-form-input"
            /> */}
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "0px 5px" }}>
            <p className="employee-title">Service description (Ar)</p>
            <ReactQuill
              value={serviceDetail?.description_ar}
              readOnly={isEdit}
              className="quill-editor rtl-quill-editor"
              onChange={(value) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  description_ar: value,
                }));
              }}
              modules={{
                toolbar: [
                  // container:"border-2",
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  // [{ 'direction': 'rtl' }],
                  ["clean"], // remove formatting button
                ],
              }}
            />
            <style>{`
                  .rtl-quill-editor .ql-editor {
                    direction: rtl;
                   text-align: right;
                                           }
                           `}</style>
            {/* <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.description_ar}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  description_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            /> */}
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
      </Row>
      <Row className="personal-details-row2" style={{ paddingBottom: "10px" }}>
        <Col sm={12} md={6} lg={6}>
          <div style={{ padding: "0px 5px" }}>
            <p className="employee-title">Where to apply</p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.where_to_apply}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  where_to_apply: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <div style={{ padding: "0px 5px" }}>
            <p className="employee-title">Where to apply (Ar)</p>
            <input
              disabled={isEdit}
              style={{ textAlign: "right" }}
              type="text"
              value={serviceDetail?.where_to_apply_ar}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  where_to_apply_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceDetails;
