import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DashboardIconSelected from "../../assets/icons/dashboardSelected.png";
import DashboardIcon from "../../assets/icons/DashboardIcon2.png";
import requests from "../../assets/icons/requests.png";
import requestsSelected from "../../assets/icons/requestsSelected.png";
import configuration from "../../assets/icons/configuration.png";
import configurationSelected from "../../assets/icons/configurationSelected.png";
import FilesIcon from "../../assets/svg/FilesIcon";
import HeadPhoneIcon from "../../assets/svg/HeadPhone";
import UserIcon from "../../assets/svg/UserIcon";
import SettingIcon from "../../assets/svg/SettingIcon";
import LogoutIcon from "../../assets/svg/LogoutIcon";
import aldhiadLogo from "../../assets/images/aldhiadLogo.png";
import "./style.css";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
interface ISideBar {
  isSkelton: boolean;
  selectedPage: string;
  setSelectedPage: Dispatch<SetStateAction<string>>;
  isAdmin?: boolean;
}
const SideBar: React.FC<ISideBar> = ({
  isSkelton,
  selectedPage,
  setSelectedPage,
  isAdmin,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <img
        src={aldhiadLogo}
        width={70}
        height={70}
        alt="img"
        style={{ position: "fixed" }}
      />
      <div style={{ position: "fixed", marginTop: "40px" }}>
        {isSkelton ? (
          <div
            style={{
              display: "flex",
              marginTop: "90px",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <SkeletonTheme baseColor="#CDCDCD" height={72} width={72}>
              <Skeleton count={5} />
            </SkeletonTheme>
          </div>
        ) : (
          <div style={{ overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                marginTop: "90px",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                height: "480px",
                overflowY: "scroll",
                marginRight: "-50px" /* Maximum width of scrollbar */,
                paddingRight: "50px" /* Maximum width of scrollbar */,
                marginLeft: "20px",
              }}
            >
              <div
                onClick={() => setSelectedPage("dashboard")}
                className="iconBg"
                style={{
                  backgroundColor:
                    selectedPage == "dashboard" ? "#DAF4E1" : "white",
                  borderRadius: "8px",
                  width: "70px",
                  height: "70px",
                  cursor: "pointer",
                }}
              >
                {selectedPage == "dashboard" ? (
                  <img src={DashboardIconSelected} />
                ) : (
                  <img src={DashboardIcon} />
                )}
              </div>
              {isAdmin && (
                <div
                  onClick={() => setSelectedPage("requests")}
                  style={{
                    backgroundColor:
                      selectedPage == "requests" ? "#DAF4E1" : "white",
                    color: selectedPage == "requests" ? "#3E6E4B" : "white",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  {selectedPage == "requests" ? (
                    <img src={requestsSelected} />
                  ) : (
                    <img src={requests} />
                  )}
                </div>
              )}
              {isAdmin && (
                <div
                  onClick={() => setSelectedPage("configuration")}
                  style={{
                    backgroundColor:
                      selectedPage == "configuration" ? "#DAF4E1" : "white",
                    color:
                      selectedPage == "configuration" ? "#3E6E4B" : "white",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  {selectedPage == "configuration" ? (
                    <img src={configurationSelected} />
                  ) : (
                    <img src={configuration} />
                  )}
                </div>
              )}
              <div style={{ cursor: "pointer" }}>
                <FilesIcon width={60} height={60} />
              </div>
              <div style={{ cursor: "pointer" }}>
                <HeadPhoneIcon width={60} height={60} />
              </div>
              <div style={{ cursor: "pointer" }}>
                <UserIcon width={60} height={60} />
              </div>
              <div style={{ cursor: "pointer" }}>
                <SettingIcon width={60} height={60} />
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isSkelton ? (
                <Skeleton />
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/login");
                    sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("selectedCard");
                    localStorage.removeItem("selectedSection");
                    sessionStorage.removeItem("token");
                  }}
                >
                  <LogoutIcon />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SideBar;
