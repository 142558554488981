import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import ImageArabic from "../../assets/images/logo.png";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import "./style.css";
import { Button } from "../../components/Button";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface ISectionDetail {
  locale?: string;
  setSectionDetailPage?: any;
  isEdit?: boolean;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedSectionDetail: any;
  setSelectedSectionDetail: any;
  setIsLoading: any;
  setRefetch: any;
}

const SectionDetail: React.FC<ISectionDetail> = ({
  locale,
  setSectionDetailPage,
  isEdit,
  setIsEdit,
  isEditDetail,
  setIsEditDetail,
  selectedSectionDetail,
  setIsLoading,
  setRefetch,
}) => {
  const [sectionDetail, setSectionDetail] = useState(
    selectedSectionDetail
      ? selectedSectionDetail
      : {
          id: "",
          title: "",
          title_ar: "",
          position: "",
          text: "",
          text_ar: "",
          page: "",
        }
  );

  // console.log("sectionDetail", sectionDetail);

  const handleAddUpdateSection = () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("title", sectionDetail?.title);
    data.append("title_ar", sectionDetail?.title_ar);
    data.append("text", sectionDetail?.text);
    data.append("text_ar", sectionDetail?.text_ar);
    data.append("position", sectionDetail?.position);
    data.append("page", "aboutus");
    data.append("is_active", sectionDetail?.is_active);
    data.append("id", sectionDetail?.id);
    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/MobileSections/addEdit",
        data
      )
      .then((res) => {
        setIsEdit(true);
        setSectionDetail({
          id: "",
          title: "",
          title_ar: "",
          position: "",
          text: "",
          text_ar: "",
          page: "",
        });
        setSectionDetail((prev: any) => ({
          ...prev,
          id: res?.data?.ref,
        }));
        setRefetch((prev: any) => !prev);
        setSectionDetailPage(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDeactivateSection = () => {
    Swal.fire({
      title: "Are you sure you want to do this?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result: any) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      const token = sessionStorage.getItem("token");
      if (result.isConfirmed && token && userId) {
        let data = new FormData();
        data.append("mobile_section_id", sectionDetail?.id);
        data.append("status", sectionDetail?.is_active == "1" ? "0" : "1");
        data.append("user_id", userId);
        data.append("token", token ? token : "");

        axios
          .post(
            "https://appadmin.buyinpk.com/portalapis/MobileSections/updateStatus",
            data
          )
          .then((res) => {
            setSectionDetail((prev: any) => ({
              ...prev,
              is_active: !prev.is_active,
            }));
            setRefetch((prev: boolean) => !prev);
            setSectionDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };
  const handleDeleteSection = () => {
    Swal.fire({
      title: "Are you sure you want to do this?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result: any) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

      if (result.isConfirmed && userId) {
        axios
          .post(
            `https://appadmin.buyinpk.com/portalapis/MobileSections/delete/${sectionDetail?.id}?user_id=${userId}`
          )
          .then((res) => {
            console.log(res);
            setRefetch((prev: boolean) => !prev);
            setSectionDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };

  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setSectionDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p className="back-p" onClick={() => setSectionDetailPage(false)}>
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">Add new list</p>
            </Col>
          )}
          {/* <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={
                  sectionDetail?.is_active == "1" ? "Edit Details" : "Active"
                }
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (sectionDetail?.is_active == "1") {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivateSection();
                  }
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={
                  !isEditDetail
                    ? "Add"
                    : isEdit
                    ? "Edit Details"
                    : "Save changes"
                }
                className={"save-changes-btn"}
                onClick={() => {
                  handleAddUpdateSection();
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setSectionDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={
                  sectionDetail?.is_active == "1" ? "Deactivate" : "Delete"
                }
                className={"deactivate-btn"}
                onClick={() =>
                  sectionDetail?.is_active == "1"
                    ? handleDeactivateSection()
                    : handleDeleteSection()
                }
              />
            )}
          </Col> */}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {/* Edit Details Button */}
            {isEdit && (
              <Button
                title="Edit Details"
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  setIsEdit(false);
                  setIsEditDetail(true);
                }}
              />
            )}
            {/* Save Changes Button */}
            {!isEdit && (
              <Button
                title={isEditDetail ? "Save Changes" : "Add"}
                className="save-changes-btn"
                onClick={() => {
                  handleAddUpdateSection();
                }}
              />
            )}
            {/* Active Button */}
            {(isEdit || isEditDetail) && sectionDetail?.is_active === "0" && (
              <Button
                title="Active"
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  handleDeactivateSection();
                }}
              />
            )}
            {!isEdit && (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setSectionDetailPage(false);
                }}
              />
            )}
            {sectionDetail?.is_active === "1" && (
              <Button
                title={"Deactivate"}
                className={"deactivate-btn"}
                onClick={() => {
                  if (sectionDetail?.is_active === "1") {
                    handleDeactivateSection();
                  }
                }}
              />
            )}
            {(isEdit || isEditDetail) && (
              <Button
                title={"Delete"}
                className={"deactivate-btn"}
                onClick={() => handleDeleteSection()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">Section Details</p>
          </Col>
        </Row>
        <Row className="mb-3" style={{ padding: "0px", margin: "0px" }}>
          <Col sm={12} md={3} lg={5} style={{ padding: "0px", margin: "0px" }}>
            <Row>
              <Col sm={12} md={3} lg={2}>
                <img className="image-div" src={ImageArabic} alt="Logo" />
              </Col>
              <Col sm={12} md={3} lg={10}>
                <div className="inputs-label-col">
                  <p className="employee-title">Title</p>
                  <input
                    disabled={isEdit}
                    type="text"
                    className="employee-form-input"
                    value={sectionDetail?.title}
                    onChange={(e) =>
                      setSectionDetail((prev: any) => ({
                        ...prev,
                        title: e.target.value,
                      }))
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={9} lg={7} style={{ padding: "0px", margin: "0px" }}>
            <Row>
              <Col sm={12} md={3} lg={8}>
                <div className="inputs-label-col">
                  <p className="employee-title">Title (Ar)</p>
                  <input
                    disabled={isEdit}
                    style={{ textAlign: "right" }}
                    type="text"
                    className="employee-form-input"
                    value={sectionDetail?.title_ar}
                    onChange={(e) =>
                      setSectionDetail((prev: any) => ({
                        ...prev,
                        title_ar: e.target.value,
                      }))
                    }
                  />
                </div>
              </Col>
              <Col
                sm={12}
                md={3}
                lg={4}
                style={{ padding: "0px 20px 0px 0px", margin: "0px" }}
              >
                <div className="inputs-label-col">
                  <p className="employee-title">Position</p>
                  <select
                    disabled={isEdit}
                    className="employee-form-input"
                    id="colors"
                    name="colors"
                    size={1}
                    value={"side_menu"}
                    onChange={(e) =>
                      setSectionDetail((prev: any) => ({
                        ...prev,
                        position: e.target.value,
                      }))
                    }
                  >
                    <option value="side_menu">Side menu</option>
                  </select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={12}>
            <div>
              <p className="employee-title">Text</p>
              <ReactQuill
                value={sectionDetail?.text}
                readOnly={isEdit}
                onChange={(value) => {
                  setSectionDetail((prev: any) => ({
                    ...prev,
                    text: value,
                  }));
                }}
                className="quill-editor"
                modules={{
                  toolbar: [
                    // container:"border-2",
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    //  [{ 'direction': 'rtl' }],
                    ["clean"], // remove formatting button
                  ],
                }}
              />
              {/* <input
                disabled={isEdit}
                type="text"
                className="emloyee-input"
                placeholder={"Enter Text"}
                style={{ width: "100%" }}
                value={sectionDetail?.text}
                onChange={(e) =>
                  setSectionDetail((prev: any) => ({
                    ...prev,
                    text: e.target.value,
                  }))
                }
              /> */}
            </div>
            <div style={{ marginTop: "10px" }}>
              <p className="employee-title">Text (Ar)</p>
              <ReactQuill
                value={sectionDetail?.text_ar}
                readOnly={isEdit}
                onChange={(value) => {
                  setSectionDetail((prev: any) => ({
                    ...prev,
                    text_ar: value,
                  }));
                }}
                className="quill-editor rtl-quill-editor"
                modules={{
                  toolbar: [
                    // container:"border-2",
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    //  [{ 'direction': 'rtl' }],
                    ["clean"], // remove formatting button
                  ],
                }}
              />
              <style>{`
      .rtl-quill-editor .ql-editor {
        direction: rtl;
        text-align: right;
      }
    `}</style>
              {/* <input
                disabled={isEdit}
                type="text"
                className="emloyee-input"
                placeholder={"Enter Text AR"}
                style={{ width: "100%" }}
                value={sectionDetail?.text_ar}
                onChange={(e) =>
                  setSectionDetail((prev: any) => ({
                    ...prev,
                    text_ar: e.target.value,
                  }))
                }
              /> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
};

export default SectionDetail;
