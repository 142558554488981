import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import ImageArabic from "../../assets/images/image-arabic.jpg";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";

import { Button } from "../../components/Button";
import { TabComponent } from "../../components/Tab";
import { ServiceForm } from "../../components/ServiceDetailForm";
import { ServiceDetails } from "../../components/ServiceDetailTabs/ServiceDetails";
import { ServiceFees } from "../../components/ServiceDetailTabs/ServiceFees";
import { TermAndCondition } from "../../components/ServiceDetailTabs/TermAndCondition";
import { ServiceFields } from "../../components/ServiceDetailTabs/ServiceFields";
import { ServiceSteps } from "../../components/ServiceDetailTabs/ServiceSteps";
import axios from "axios";
import FormData from "form-data";
import qs from "qs";
import Swal from "sweetalert2";

interface IServiceDetail {
  locale?: string;
  setServiceDetailPage?: any;
  isEdit: any;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedService?: any;
  setRefetch?: any;
  isLoading: boolean;
  setIsLoading: any;
}

interface DataItem {
  id: string;
  name: string;
  name_ar: string;
  feild_type: string;
  status: string;
}

interface DataItem2 {
  id: string;
  step_name: string;
  step_name_ar: string;
  responsible_group: string;
  notification_template: string;
  is_notification: string;
  is_active: string;
  notification_id: any;
}

const ServiceDetail: React.FC<IServiceDetail> = ({
  locale,
  setServiceDetailPage,
  isEdit,
  setIsEdit,
  isEditDetail,
  setIsEditDetail,
  selectedService,
  setRefetch,
  isLoading,
  setIsLoading,
}) => {
  // console.log("selectedService",selectedService)
  const [serviceDetail, setServiceDetail] = useState(
    isEdit
      ? selectedService
      : {
          id: "",
          service_code: "",
          service_name: "",
          service_name_ar: "",
          department_id: "",
          without_selecting_plants_step: 0,
          description: "",
          description_ar: "",
          where_to_apply: "",
          where_to_apply_ar: "",
          fee: "",
          fee_ar: "",
          work_hours: "",
          work_hours_ar: "",
          contact_no: "",
          total_service_time: "",
          // no_of_days: "",
          additional_notes: "",
          terms_and_conditions: "",
          terms_and_conditions_ar: "",
        }
  );
  console.log("🚀 ~ serviceDetail:", serviceDetail);
  const [serviceField, setServiceFields] = useState<DataItem[]>(
    serviceDetail?.service_fields ? serviceDetail?.service_fields : []
  );
  // console.log("🚀 ~ serviceField:", serviceField);
  const [serviceSteps, setServiceSteps] = useState<DataItem2[]>(
    serviceDetail.service_steps ? serviceDetail?.service_steps : []
  );
  // console.log("🚀 ~ serviceSteps:", serviceSteps);
  const handleFieldsInputChange = (
    value: string,
    dataIndex: keyof DataItem,
    record: DataItem
  ) => {
    if (serviceField && serviceField.length > 0) {
      const newData = [...serviceField];
      const index = newData.findIndex((item: any) => item.id === record.id);

      if (index > -1) {
        // Update the data array with the new value
        newData[index][dataIndex] = value;

        // Update the state with the modified data
        // console.log(newData);

        setServiceFields(newData);
      }
    }
  };
  const handleStepsInputChange = (
    value: string,
    dataIndex: keyof DataItem2,
    record: DataItem2
  ) => {
    if (serviceSteps && serviceSteps.length > 0) {
      const newData = [...serviceSteps];
      const index = newData.findIndex(
        (item: DataItem2) => item.id === record.id
      );

      if (index > -1) {
        // Update the data array with the new value
        newData[index][dataIndex] = value;

        // Update the state with the modified data
        // console.log(newData);

        setServiceSteps(newData);
      }
    }
  };
  const [designation, setDesignation] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [systemLists, setSystemLists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId: any = sessionStorage.getItem("userDetails");
        const response = await axios.get(
          `https://appadmin.buyinpk.com/portalapis/SystemLists/getAll??user_id=${userId?.id}`
        );
        setSystemLists(response?.data?.data);
      } catch (error) {
        console.log("Error fetching employees:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId: any = sessionStorage.getItem("userDetails");
        const response = await axios.get(
          `https://appadmin.buyinpk.com/portalapis/Designations/getAll?user_id=${userId?.id}`
        );
        setDesignation(response.data);
      } catch (error) {
        console.log("Error fetching employees:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://appadmin.buyinpk.com/portalapis/Notifications/getStepsNotification"
        );
        setNotifications(response.data);
      } catch (error) {
        console.log("Error fetching employees:", error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteField = (record: DataItem2) => {
    axios
      .post(
        `https://appadmin.buyinpk.com/portalapis/Services/deleteServiceField/${record?.id}`
      )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    const newData = serviceField.filter((item) => item.id !== record.id);
    setServiceFields(newData);
  };

  const handleDeleteStep = (record: DataItem) => {
    axios
      .post(
        `https://appadmin.buyinpk.com/portalapis/Services/deleteServiceStep/${record?.id}`
      )
      .then((res) => {
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
    const newData = serviceSteps.filter((item) => item.id !== record.id);
    setServiceSteps(newData);
  };
  const tabs = [
    {
      event_key: "service-details",
      title: "Service Details",
      component: (
        <ServiceDetails
          isEdit={isEdit}
          serviceDetail={serviceDetail}
          setServiceDetail={setServiceDetail}
        />
      ),
    },
    {
      event_key: "service-fees",
      title: "Service Fees",
      component: (
        <ServiceFees
          isEdit={isEdit}
          serviceDetail={serviceDetail}
          setServiceDetail={setServiceDetail}
        />
      ),
    },
    {
      event_key: "term-and-condition",
      title: "Term And Condition",
      component: (
        <TermAndCondition
          isEdit={isEdit}
          serviceDetail={serviceDetail}
          setServiceDetail={setServiceDetail}
        />
      ),
    },
    {
      event_key: "service-fields",
      title: "Service Fields",
      component: (
        <ServiceFields
          serviceField={serviceField}
          setServiceFields={setServiceFields}
          handleFieldsInputChange={handleFieldsInputChange}
          handleDeleteField={handleDeleteField}
          isEdit={isEdit}
          systemLists={systemLists}
        />
      ),
    },
    {
      event_key: "service-steps",
      title: "Service Steps",
      component: (
        <ServiceSteps
          serviceSteps={serviceSteps}
          setServiceSteps={setServiceSteps}
          handleStepsInputChange={handleStepsInputChange}
          handleDeleteStep={handleDeleteStep}
          isEdit={isEdit}
          options={designation}
          Notifications={notifications}
        />
      ),
    },
  ];
  if (serviceDetail.without_selecting_plants_step) {
  }
  const [selectedTab, setSelectedTab] = useState("service-details");
  const handleTabSelect = (selectedKey: any) => {
    setSelectedTab(selectedKey);
  };

  // Function which will validate the input data whenever submit button is clicked.

  function validateForm() {
    // Check if the First Name is an Empty string or not.

    if (serviceDetail?.service_name.length == 0) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "error",
        title: "Service name can not be empty",
      });
      // alert("Service name can not be empty");
      return;
    }

    if (serviceDetail?.service_name_ar.length == 0) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "error",
        title: "Service name arabic can not be empty",
      });
      // alert("Service name arabic can not be empty");
      return;
    }

    if (serviceDetail?.department_id == 0 || "") {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "error",
        title: "Department can not be empty",
      });
      // alert("Department can not be empty");
      return;
    }

    if (serviceField.length > 0) {
      for (let i = 0; i < serviceField.length; i++) {
        const step = serviceField[i];

        if (!step?.name || step?.name.length == 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: `Step name cannot be empty for service fields ${i + 1}`,
          });
          return;
        }

        if (!step?.name_ar || step?.name_ar.length == 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: `Step name Arabic cannot be empty for service fields ${
              i + 1
            }`,
          });
          // showToast(
          //   "error",
          //   `Step name Arabic cannot be empty for step ${i + 1}`
          // );
          return;
        }

        if (!step?.feild_type || step?.feild_type.length == 0 || "") {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: `Field type cannot be empty for service fields ${i + 1}`,
          });
          // showToast(
          //   "error",
          //   `Responsible group cannot be empty for step ${i + 1}`
          // );
          return;
        }
      }
    }

    if (serviceSteps.length > 0) {
      for (let i = 0; i < serviceSteps.length; i++) {
        const step = serviceSteps[i];

        if (!step?.step_name || step?.step_name.length == 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: `Step name cannot be empty for service steps ${i + 1}`,
          });
          return;
        }

        if (!step?.step_name_ar || step?.step_name_ar.length == 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: `Step name Arabic cannot be empty for service steps ${
              i + 1
            }`,
          });
          // showToast(
          //   "error",
          //   `Step name Arabic cannot be empty for step ${i + 1}`
          // );
          return;
        }

        if (
          !step?.responsible_group ||
          step?.responsible_group.length == 0 ||
          ""
        ) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: `Responsible group cannot be empty for service steps ${
              i + 1
            }`,
          });
          // showToast(
          //   "error",
          //   `Responsible group cannot be empty for step ${i + 1}`
          // );
          return;
        }

        if (!step?.notification_id || step?.notification_id.length == 0 || "") {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: `Notification  cannot be empty for service steps ${i + 1}`,
          });
          // showToast(
          //   "error",
          //   `Notification template cannot be empty for step ${i + 1}`
          // );
          return;
        }
      }
    }

    // Check if the Email is an Empty string or not.

    // if (email.length == 0) {
    //   alert("Invalid Form, Email Address can not be empty");
    //   return;
    // }

    // check if the password follows constraints or not.

    // if password length is less than 8 characters, alert invalid form.

    // if (password.length < 8) {
    //   alert(
    //     "Invalid Form, Password must contain greater than or equal to 8 characters."
    //   );
    //   return;
    // }

    // variable to count upper case characters in the password.
    let countUpperCase = 0;
    // variable to count lowercase characters in the password.
    let countLowerCase = 0;
    // variable to count digit characters in the password.
    let countDigit = 0;
    // variable to count special characters in the password.
    let countSpecialCharacters = 0;

    // for (let i = 0; i < password.length; i++) {
    //   const specialChars = [
    //     "!",
    //     "@",
    //     "#",
    //     "$",
    //     "%",
    //     "^",
    //     "&",
    //     "*",
    //     "(",
    //     ")",
    //     "_",
    //     "-",
    //     "+",
    //     "=",
    //     "[",
    //     "{",
    //     "]",
    //     "}",
    //     ":",
    //     ";",
    //     "<",
    //     ">",
    //   ];

    //   if (specialChars.includes(password[i])) {
    //     // this means that the character is special, so increment countSpecialCharacters
    //     countSpecialCharacters++;
    //   } else if (!isNaN(password[i] * 1)) {
    //     // this means that the character is a digit, so increment countDigit
    //     countDigit++;
    //   } else {
    //     if (password[i] == password[i].toUpperCase()) {
    //       // this means that the character is an upper case character, so increment countUpperCase
    //       countUpperCase++;
    //     }
    //     if (password[i] == password[i].toLowerCase()) {
    //       // this means that the character is lowercase, so increment countUpperCase
    //       countLowerCase++;
    //     }
    //   }
    // }

    // if (countLowerCase == 0) {
    //   // invalid form, 0 lowercase characters
    //   alert("Invalid Form, 0 lower case characters in password");
    //   return;
    // }

    // if (countUpperCase == 0) {
    //   // invalid form, 0 upper case characters
    //   alert("Invalid Form, 0 upper case characters in password");
    //   return;
    // }

    // if (countDigit == 0) {
    //   // invalid form, 0 digit characters
    //   alert("Invalid Form, 0 digit characters in password");
    //   return;
    // }

    // if (countSpecialCharacters == 0) {
    //   // invalid form, 0 special characters characters
    //   alert("Invalid Form, 0 special characters in password");
    //   return;
    // }

    // if all the conditions are valid, this means that the form is valid

    return true;
  }

  const handleAddService = () => {
    if (!validateForm()) {
      return; // Stop execution if validation fails
    }
    setIsLoading(true);

    let data = new FormData();
    data.append("code", serviceDetail?.service_code);
    data.append("name", serviceDetail?.service_name);
    data.append("name_ar", serviceDetail?.service_name_ar);
    data.append("department_id", serviceDetail?.department_id);
    data.append("description", serviceDetail?.description);
    data.append("description_ar", serviceDetail?.description_ar);
    data.append("where_to_apply", serviceDetail?.where_to_apply);
    data.append("where_to_apply_ar", serviceDetail?.where_to_apply_ar);
    data.append("is_active", 1);
    data.append("id", serviceDetail?.id);
    data.append("fee", serviceDetail?.fee);
    data.append("fee_ar", serviceDetail?.fee_ar);
    data.append("work_hours", serviceDetail?.work_hours);
    data.append("work_hours_ar", serviceDetail?.work_hours_ar);
    data.append("contact_no", serviceDetail?.contact_no);
    data.append("total_service_time", serviceDetail?.total_service_time);
    data.append("no_of_days", serviceDetail?.no_of_days);
    data.append("additional_notes", serviceDetail?.additional_notes);
    data.append("terms_and_conditions", serviceDetail?.terms_and_conditions);
    data.append(
      "without_select_plants_step",
      serviceDetail?.without_selecting_plants_step === false ||
        serviceDetail?.without_selecting_plants_step === 0
        ? 0
        : 1
    );
    data.append(
      "terms_and_conditions_ar",
      serviceDetail?.terms_and_conditions_ar
    );

    axios
      .post("https://appadmin.buyinpk.com/portalapis/Services/addEdit", data)
      .then((response: any) => {
        setServiceDetail((prev: any) => ({
          ...prev,
          id: response?.data?.ref,
          service_code: response?.data?.code,
        }));
        const fieldsArr = serviceField?.map((field: any) => {
          return {
            id: typeof field?.id !== "number" ? field?.id : null,
            service_id: response?.data?.ref,
            name: field?.name,
            name_ar: field?.name_ar,
            feild_type: field?.feild_type,
            is_active:
              field?.is_active === "De-active" || field?.is_active === "0"
                ? 0
                : 1,
            is_required:
              field?.is_required === "De-active" || field?.is_required === "0"
                ? 0
                : 1,
            ...(field?.feild_type == "dropdown" && {
              system_list_id: field?.system_list_id,
            }),
          };
        });

        axios
          .post(
            "https://appadmin.buyinpk.com/portalapis/Services/saveServiceFields",
            JSON.stringify(fieldsArr)
          )
          .then((response2: any) => {
            setIsEdit(true);
          })
          .catch((err: any) => {
            console.log(err);
          });
        const stepsArr = serviceSteps?.map((step) => {
          return {
            id: typeof step?.id !== "number" ? step?.id : null,
            service_id: response?.data?.ref,
            step_name: step?.step_name,
            step_name_ar: step?.step_name_ar,
            responsible_group: step?.responsible_group,
            notification_template: null,
            notification_id: step?.notification_id,
            is_notification: step?.is_notification == "De-active" ? 0 : 1,
            is_active: step?.is_active == "De-active" ? 0 : 1,
          };
        });

        axios
          .post(
            "https://appadmin.buyinpk.com/portalapis/Services/saveServiceStep",
            JSON.stringify(stepsArr)
          )
          .then((response3: any) => {
            // console.log("🚀 ~ .then ~ response3:", response3)
            setIsEdit(true);
          })
          .catch((err: any) => {
            console.log(err);
          });
        setRefetch((prev: any) => !prev);
        setServiceDetailPage(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDeactivateService = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = qs.stringify({
      service_id: selectedService?.id,
      status: selectedService?.is_active ? "0" : "1",
      user_id: userId,
    });

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/Services/updateStatus",
        data
      )
      .then((res) => {
        setServiceDetail((prev: any) => ({
          ...prev,
          is_active: !prev.is_active,
        }));
        setRefetch((prev: boolean) => !prev);
        setServiceDetailPage(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteService = () => {
    Swal.fire({
      title: "Are you sure you want to delete this service?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result: any) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      if (result.isConfirmed && userId && selectedService) {
        axios
          .post(
            `https://appadmin.buyinpk.com/portalapis/Services/delete/${selectedService?.id}?user_id=${userId}`
          )
          .then((res) => {
            // console.log(res);
            setRefetch((prev: boolean) => !prev);
            setServiceDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };
  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setServiceDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p className="back-p" onClick={() => setServiceDetailPage(false)}>
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">Add new service</p>
            </Col>
          )}
          {/* <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={serviceDetail?.is_active ? "Edit Details" : "Active"}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (serviceDetail?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivateService();
                  }
                }}
              />
            ) : (
              ""
            )}
            {
              !isEdit ? (
                <Button
                  title={
                    !isEditDetail
                      ? "Add"
                      : isEdit
                      ? "Edit Details"
                      : "Save changes"
                  }
                  className={"save-changes-btn"}
                  onClick={() => {
                    handleAddService();
                  }}
                />
              ) : (
                ""
              )
              // (
              //   <Button
              //     title={<FormattedMessage id="reset_password" />}
              //     className={"reset-password-btn"}
              //     onClick={() => {}}
              //   />
              // )
            }
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  // setServiceDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={serviceDetail?.is_active ? "Deactivate" : "Delete"}
                className={"deactivate-btn"}
                onClick={() =>
                  serviceDetail?.is_active
                    ? handleDeactivateService()
                    : handleDeleteService()
                }
              />
            )}
          </Col> */}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {/* Edit Details Button */}
            {isEdit && (
              <Button
                title="Edit Details"
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  setIsEdit(false);
                  setIsEditDetail(true);
                }}
              />
            )}
            {/* Save Changes Button */}
            {!isEdit && (
              <Button
                title={isEditDetail ? "Save Changes" : "Add"}
                className="save-changes-btn"
                onClick={() => {
                  handleAddService();
                }}
              />
            )}
            {/* Active Button */}
            {(isEdit || isEditDetail) && serviceDetail?.is_active === false && (
              <Button
                title="Active"
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  handleDeactivateService();
                }}
              />
            )}
            {!isEdit && (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setServiceDetailPage(false);
                }}
              />
            )}
            {serviceDetail?.is_active && (
              <Button
                title={"Deactivate"}
                className={"deactivate-btn"}
                onClick={() => {
                  if (serviceDetail?.is_active) {
                    handleDeactivateService();
                  }
                }}
              />
            )}
            {(isEdit || isEditDetail) && (
              <Button
                title={"Delete"}
                className={"deactivate-btn"}
                onClick={() => handleDeleteService()}
              />
            )}
          </Col>
          {/* <hr /> */}
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">Service Details</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={9} lg={12} className="inputs-col">
            <ServiceForm
              isEdit={isEdit}
              serviceDetail={serviceDetail}
              setServiceDetail={setServiceDetail}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} className="tabs-col">
            <TabComponent
              selectedTab={selectedTab}
              handleTabSelect={handleTabSelect}
              tabs={tabs}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ServiceDetail;
